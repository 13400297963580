import { ChevronLeftIcon, ChevronRightIcon } from '@fluidtruck/icons';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import {
  ArrowContainer,
  Container,
  DotMatrix,
  ImageContainer,
  ImageDefault,
  PositionedChevronLeft,
  PositionedChevronRight,
  SelectedDot,
  UnselectedDot,
} from './ImageCarouselStyles';

export const ImageCarousel = ({ images }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length || 0;

  const handleNext = () => {
    if (activeStep + 1 < maxSteps) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep - 1 >= 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Container>
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <ImageContainer key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <ImageDefault src={step} alt="Kingbee Vans" />
            ) : null}
          </ImageContainer>
        ))}
      </SwipeableViews>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          position: 'absolute',
          zIndex: 5,
          bottom: 15,
          left: 0,
          right: 0,
          margin: 'auto',
          width: 184,
        }}
      >
        <Grid item>
          <ArrowContainer onClick={handleBack} disabled={activeStep === 0}>
            <PositionedChevronLeft>
              <ChevronLeftIcon color="fluidBlue" />
            </PositionedChevronLeft>
          </ArrowContainer>
        </Grid>
        <Grid item>
          <DotMatrix>
            {images.map((img, index) => {
              if (index < 4) {
                if (index === activeStep || (index === 3 && activeStep >= 4)) {
                  return <SelectedDot key={index} />;
                }
                return <UnselectedDot key={index} />;
              }
              return <React.Fragment key={index} />;
            })}
          </DotMatrix>
        </Grid>
        <Grid item>
          <ArrowContainer
            onClick={handleNext}
            disabled={activeStep + 1 === maxSteps}
          >
            <PositionedChevronRight>
              <ChevronRightIcon color="fluidBlue" />
            </PositionedChevronRight>
          </ArrowContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.array,
};

ImageCarousel.defaultProps = {
  images: [],
};
